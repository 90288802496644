import { breakpoint } from 'src/styles'
import { CSSTransition } from 'react-transition-group'
import { Dots } from './Dots'
import { getScrollbarWidth } from 'src/utils/getScrollbarWidth'
import { lockScroll } from 'src/utils/lockScroll'
import { Portal } from 'src/components/Portal'
import { unlockScroll } from 'src/utils/unlockScroll'

const fadeTransitionName = 'fade'
const appearDuration = 500

const slideTransitionName = 'slide'
const slideDuration = 500

const Dialog = ({
  canEscape = true,
  children,
  className,
  closeOnOverlayClick = true,
  containerStyle,
  overlayStyle = {},
  disableTouchOnMobile = false, // temporary for the mobile modal on the WOP page to disable moving around the page
  dotIndex,
  headerClassName = 'dialog-dismiss',
  hideScrollbar = false,
  isOpen = false,
  isScrollLocked = true,
  numDots,
  onClose,
  onClosed,
  padding = '0 25px',
  returnFocusRef,
  showDots = false,
  showRainbowRule = false,
  parentElement,
  backgroundImage = null,
  ...props
}) => {
  const [renderContainer, setRenderContainer] = React.useState(false)
  const [scrollBarWidth] = React.useState(getScrollbarWidth())
  const modalRef = React.createRef()

  const closeModal = () => {
    if (typeof onClose === 'function') onClose()
    if (returnFocusRef) returnFocusRef.focus()
  }

  const onOverlayClick = (event) => {
    event.stopPropagation()
    if (closeOnOverlayClick) closeModal()
  }

  const handleTabKey = (e) => {
    const focusableModalElements =
      isOpen &&
      modalRef.current.querySelectorAll(
        'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
      )
    const firstElement = isOpen && focusableModalElements[0]
    const lastElement = isOpen && focusableModalElements[focusableModalElements.length - 1]

    if (firstElement && lastElement && !e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    }

    if (firstElement && lastElement && e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  const handleEscapeKey = () => {
    if (isOpen && canEscape) closeModal()
  }

  const keyListenerMap = {
    27: handleEscapeKey,
    9: handleTabKey,
  }

  // Lock screen scroll and start focus
  React.useEffect(() => {
    if (isOpen) {
      if (isScrollLocked) lockScroll(scrollBarWidth)

      const focusableModalElements = modalRef.current.querySelectorAll(
        'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
      )
      let firstElement = focusableModalElements[0]
      if (firstElement?.classList?.contains(headerClassName) && focusableModalElements.length > 1)
        firstElement = focusableModalElements[1]
      if (firstElement) firstElement.focus()
    } else if (isScrollLocked) unlockScroll(scrollBarWidth)

    return () => {
      if (isScrollLocked) unlockScroll(scrollBarWidth)
    }
  }, [isOpen])

  // Add event handlers for key downs
  React.useEffect(() => {
    const keyListener = (e) => {
      const listener = keyListenerMap[e.keyCode]
      return listener && listener(e)
    }

    document.addEventListener('keydown', keyListener)

    return () => document.removeEventListener('keydown', keyListener)
  })

  return (
    <Portal parentElement={parentElement}>
      <CSSTransition
        key='overlay'
        in={isOpen}
        appear={isOpen}
        onEntering={() => setRenderContainer(true)}
        onExiting={() => setRenderContainer(false)}
        classNames={fadeTransitionName}
        timeout={appearDuration}
        unmountOnExit
        {...(typeof onClosed === 'function' && { onExited: () => onClosed() })}
      >
        <Styles.Overlay
          onClick={(e) => onOverlayClick(e)}
          disableTouchOnMobile={disableTouchOnMobile}
          style={overlayStyle}
          backgroundImage={backgroundImage}
        >
          <CSSTransition
            key='dialog'
            in={renderContainer}
            classNames={slideTransitionName}
            timeout={slideDuration}
          >
            <Styles.Container style={containerStyle} padding={padding}>
              <Styles.Content
                onClick={(e) => e.stopPropagation()}
                className={className}
                hideScrollbar={hideScrollbar}
                ref={modalRef}
                showRainbowRule={showRainbowRule}
                {...props}
              >
                {children}
                {showDots && <Dots dotIndex={dotIndex} numDots={numDots} />}
              </Styles.Content>
            </Styles.Container>
          </CSSTransition>
        </Styles.Overlay>
      </CSSTransition>
    </Portal>
  )
}

const Styles = {
  Overlay: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(151, 151, 151, 0.5);
    backdrop-filter: blur(5px);
    height: 100vh;
    width: 100vw;
    z-index: 1001;

    ${({ backgroundImage }) => css`
      background-image: url(${backgroundImage});
      background-size: 100% 100%;
    `}

    ${breakpoint.mediumAndDown} {
      ${({ disableTouchOnMobile }) =>
        disableTouchOnMobile &&
        css`
          touch-action: none;
        `}
    }

    transition: opacity ${appearDuration}ms;

    &.${fadeTransitionName}-enter {
      opacity: 0;
    }

    &.${fadeTransitionName}-enter-active {
      opacity: 1;
    }

    &.${fadeTransitionName}-exit {
      opacity: 1;
    }

    &.${fadeTransitionName}-exit-active {
      opacity: 0;
    }
  `,
  Container: styled.div`
    position: relative;
    z-index: 1002;
    transform: translateY(-100%);
    margin: 0 auto;
    ${({ padding }) =>
      padding &&
      css`
        padding: ${padding};
      `}

    transition: top ${slideDuration}ms;
    &.${slideTransitionName}-enter {
      top: -50%;
      transform: translateY(-50%);
    }

    &.${slideTransitionName}-enter-active {
      top: 50%;
      transform: translateY(-50%);
    }

    &.${slideTransitionName}-enter-done {
      top: 50%;
      transform: translateY(-50%);
    }

    &.${slideTransitionName}-exit {
      top: 50%;
      transform: translateY(-50%);
    }

    &.${slideTransitionName}-exit-active {
      top: -50%;
      transform: translateY(-50%);
    }
  `,
  Content: styled.div`
    position: relative;
    background-color: white;
    min-width: 100px;
    min-height: 100px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);

    padding-top: ${({ showRainbowRule }) => (showRainbowRule ? '6px' : 0)};
    margin: 0 auto;

    ${({ showRainbowRule }) =>
      showRainbowRule &&
      css`
        padding-top: 6px;
        ::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 6px;
          top: 0;
          left: 0;
          background: linear-gradient(
            90deg,
            var(--yellow) 0.7%,
            var(--mediumPink) 29.86%,
            var(--lightBlue) 52.12%,
            var(--lavender) 99.2%
          );
          transform: matrix(-1, 0, 0, 1, 0, 0);
        }
      `}
    ${({ hideScrollbar }) =>
      hideScrollbar &&
      css`
        ::-webkit-scrollbar {
          display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
      `}
  `,
}

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
  returnFocusRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: typeof Element === 'undefined' ? PropTypes.any : PropTypes.instanceOf(Element),
    }),
  ]),
  style: PropTypes.object,
  dotIndex: PropTypes.number,
  numDots: PropTypes.number,
  showDots: PropTypes.bool,
  showRainbowRule: PropTypes.bool,
}

export default Dialog
