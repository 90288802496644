import { Dismiss } from 'src/components/Display/Dismiss'

const Header = ({ onClose, onClosed, isDismissDisabled = false, children, ...props }) => (
  <Styles.HeaderContainer {...props}>
    <Styles.Header>{children}</Styles.Header>
    <Styles.Dismiss
      className='dialog-dismiss'
      isDisabled={isDismissDisabled}
      onClick={() => {
        if (typeof onClose === 'function') onClose()
        if (typeof onClosed === 'function') setTimeout(() => onClosed(), 250)
      }}
      disabled={isDismissDisabled}
    >
      <Dismiss />
    </Styles.Dismiss>
  </Styles.HeaderContainer>
)

Header.propTypes = {
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
}

const Styles = {
  HeaderContainer: styled.div`
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ noBorder = false }) =>
      !noBorder &&
      css`
        border-bottom: 1px solid #dee2e6;
      `}
  `,
  Header: styled.h2`
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 0;
  `,
  Dismiss: styled.button`
    background: none;
    color: inherit;
    border: none;
    outline: inherit;

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        opacity: 0.25;
        :focus {
          outline: 0;
        }
      `}
  `,
}

export default Header
