import { mixin } from 'src/styles'

export const Spinner = ({ minHeight = 0 }) => (
  <Styles.Container style={{ minHeight }}>
    <Styles.Spinner />
  </Styles.Container>
)

const Styles = {
  Container: styled.div`
    ${mixin.flexCenterBoth}
    padding: 1.5rem;
    width: 100%;
  `,
  Spinner: styled.div`
    @keyframes spinner-border {
      to {
        transform: rotate(360deg);
      }
    }

    animation: 0.75s linear infinite spinner-border;
    border: 0.25em solid currentColor;
    border-radius: 50%;
    border-right-color: transparent;
    display: inline-block;
    height: 2rem;
    opacity: 0.1;
    vertical-align: text-bottom;
    width: 2rem;
  `,
}

export const styleSpinner = Styles.Spinner
