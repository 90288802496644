import ReactDOM from 'react-dom'

export const Portal = ({
  children,
  className = 'root-portal',
  element = 'div',
  parentElement = document.body,
}) => {
  const [container] = React.useState(() => {
    const el = document.createElement(element)
    el.classList.add(className)
    return el
  })

  React.useEffect(() => {
    parentElement?.appendChild?.(container)

    return () => {
      parentElement?.removeChild?.(container)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentElement])

  return ReactDOM.createPortal(children, container)
}

export default Portal

Portal.propTypes = {
  parentElement: typeof Element === 'undefined' ? PropTypes.any : PropTypes.instanceOf(Element),
}
